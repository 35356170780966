@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Space+Grotesk:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

main {
  display: block; /* new */
  /*width: 200px;*/
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.snow {
  animation:
    fall ease-in infinite,
    sway ease-in-out infinite;
  color: skyblue;
  position: absolute;
}

@keyframes fall {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 100vh;
    opacity: 1;
  }
}

@keyframes sway {
  0% {
    margin-left: 0;
  }
  25% {
    margin-left: 50px;
  }
  50% {
    margin-left: -50px;
  }
  75% {
    margin-left: 50px;
  }
  100% {
    margin-left: 0;
  }
}
