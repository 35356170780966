.introjs-hint {
  z-index: 9999;
}

.introjs-tooltiptext {
  min-width: 280px;
  background-color: white;
  border: 1px solid grey;
  font-size: 13px;
}

.introjs-button {
  background-color: #3cd958;
  color: white;
  padding: 0.2rem 0.5rem;
  text-shadow: none;
}
